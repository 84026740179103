$anime-time: 8s;

$timing : 265ms;
$iconColor : #00B5F5;
$accent : #002A8F;
$bluefade : #0043E0;
$gradient : #00B5F5;

$box-size: 250px;
$clip-distance: .05;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;

$main-color: rgb(75, 167, 0);
$main-color-dark: rgb(5, 117, 0);
$main-color-opacity: rgb(75, 167, 0, 0.4);
$main-color-dark-opacity: rgb(5, 117, 0, 0.4);

$logo-path: url('../images/logoFlexGreen.png');

@font-face {
    font-family: 'Antonio';
    src: url('../fonts/Antonio.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: url('../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: url('../fonts/OpenSans.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

%full-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.logoContent {
    display: flex;
    position: fixed;
    @extend %full-fill;
    width: $box-size;
    height: $box-size;
    margin: auto;
    background: $logo-path no-repeat 50% / 90% rgba(#000, .1);
    color: $main-color;
    box-shadow: inset 0 0 0 1px rgba($main-color, .5);
    cursor: pointer;

    &::before,
    &::after {
        @extend %full-fill;
        content: '';
        z-index: -1;
        margin: -1 * $clip-distance * 100%;
        box-shadow: inset 0 0 0 $path-width;
        animation: clipMe $anime-time linear infinite;
    }

    &::before {
        animation-delay: $anime-time * -.5;
    }

    // for debug
    &:hover {

        &::after,
        &::before {
            background-color: rgba(0 0 0/ 0.3);
        }
    }

}

.logoContent h6 {
    margin: auto auto 1em auto;
}

@keyframes clipMe {

    0%,
    100% {
        clip: rect(0px, $clip-size, $path-width, 0px);
    }

    25% {
        clip: rect(0px, $path-width, $clip-size, 0px);
    }

    50% {
        clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px);
    }

    75% {
        clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width);
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
#root {
    font-family: 'OpenSans', sans-serif;
    color: #fff;
    height: 100%;
    z-index: 2;
    background-color: #000;
}

#root {
    height: 100%;
}

.App-logo {
    cursor: pointer;
    max-width: 235px;
    width: 100%;
}

.App {
    height: calc(100% - 99px);
    margin-top: 99px;
    position: relative;
    z-index: -2;
}

.scrollbar-container {
    z-index: 2;
}

nav {
    transition: background-color 0.5s ease;
    z-index: -1;
    top: 0;
}

@media (max-width: 555px) {
    .navbar-language {
        margin-top: 0.25rem !important;
        justify-content: end !important;
    }
}

.navbar {
    flex-wrap: nowrap !important;
}

@media (max-width: 1100px) {

    .navbar {
        flex-wrap: wrap !important;
    }

    nav .navParent:first-of-type {
        position: absolute;
    }

    nav .navParent {
        width: 100% !important;
    }

    nav .navParent:last-of-type {
        padding-right: 0px !important;
    }

    .toolbar__search {
        margin: 1rem auto 1rem auto !important;
        justify-content: center !important;
    }

    .navbar-pages {
        margin-top: 1rem !important;
        justify-content: center !important;
    }

    .navbar-pages {
        margin: 0.5rem 0rem 1rem auto !important;
    }

    .navbar-language {
        margin: 0.5rem 1rem 1rem auto !important;
    }

    .App-logo {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .App {
        margin-top: 170px;
    }

    .toolbar__search {
        max-width: unset;
    }

}

.projectContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.swiper-slide {
    min-width: 250px;
}

.react-horizontal-scrolling-menu--scroll-container {
    width: auto !important;
}

input::placeholder {
    color: rgba(255 255 255 /0.5);
}

.loaderScreen {
    margin: auto;
}

.copyright {
    font-size: 12px;
}

.video-container .videoFooter {
    position: fixed !important;
}

.videoFooter {
    padding: 3rem 1rem 1rem 1rem;
    bottom: 0px;
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $main-color;
    z-index: 10001;
}

.fs-14 {
    font-size: 14px !important;
}

.toolbar__search {
    margin: 1rem 0rem 1rem auto;
}

.react-horizontal-scrolling-menu--inner-wrapper {
    justify-content: center;
}

nav .navParent:last-of-type {
    padding-right: 1.5rem;
}

nav .navParent:first-of-type,
nav .navParent:last-of-type {
    width: 40vw;
}

nav .navParent {
    padding: 0.25rem 0rem;
    width: 20vw;
}

.navbar {
    padding: 0px !important;
    align-items: start !important;
}

.navbar-pages {
    justify-content: end;
}

.navbar-language {
    justify-content: start;
}

.navbar-pages,
.navbar-language {
    margin: 1rem 0rem 1rem auto;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    padding-left: 0;
    list-style: none;
}

.navbar-language h6,
.navbar-pages .nav-item a {
    letter-spacing: 0.5px;
    padding: 0.5rem 1rem;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Antonio', 'sans-serif';
    font-size: 17px;
}

.background-main-color {
    background-color: $main-color !important;
}

.text-main-color {
    color: $main-color !important;
}

.navbar-language .nav-item a.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out;
}

.react-horizontal-scrolling-menu--item {
    margin-bottom: 0.5rem;
    display: flex;
    padding: 0px;
}

.react-horizontal-scrolling-menu--item .btn:hover,
.react-horizontal-scrolling-menu--item .btn:focus {
    color: $main-color;
}

.react-horizontal-scrolling-menu--item .btn {
    color: #fff;
    text-transform: none;
    font-weight: 400;
    margin: 0.5rem 0.25rem 0rem 0.25rem;
    padding: 0.5rem 0.75rem;
}

.react-photo-gallery--gallery {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}

.react-photo-gallery--gallery img {
    padding: 0.5rem;
    border-radius: 5px;
}

.text-main-color {
    color: $main-color !important;
}

.navbar {
    background-color: #000;
}

.projectTitle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    flex-wrap: nowrap;
}

.projectsListNav {
    margin: 0px 1.5rem;
    display: inline-flex;
    justify-content: center;
}

.projectsListNav h6:not(:last-of-type) {
    margin-right: 2rem;
}

.horizontally-centered {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.vertically-centered {
    display: inline-flex !important;
    ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
}

.gradient {
    align-items: stretch;
    margin: 1em 0;
    padding-bottom: 15px;
    line-height: 2em;
    background-color: $main-color;
    border-radius: 2px;
}

.divider {
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    display: flex;
}

.divider::before {
    content: "";
    flex: 1;
}

.gradient::before {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: linear-gradient(0.25turn, $main-color, $main-color-dark);
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Style the video element to cover the entire container */
.video-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Optionally, center the content within the container */
.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    /* You can set your desired text color */
    font-size: 24px;
}

.black-and-white-filter {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
}

.toolbar {
    overflow: hidden;
}

.toolbar__search {
    max-width: 350px;
    margin: 1rem 0rem 1rem auto;
    background-color: transparent;
}

.cursor-pointer {
    cursor: pointer;
}

.toolbar__search input[type=text] {
    color: #fff;
    margin-left: 1rem;
    background-color: transparent;
    height: 30px;
    border: 0;
    outline: 0 !important;
    width: 100%;
    border-bottom: 1px solid #fff;
}

.toolbar__search__close {
    -webkit-transition: color .3s;
    transition: color .3s;
}

.projectCardShadow {
    padding: 20px 30px;
    width: 100%;
    padding-top: 3rem;
    background: rgb(255, 222, 0);
    background: linear-gradient(180deg, rgba(255, 222, 0, 0) 0%, rgba(0, 0, 0, 0.5214460784313726) 30%, rgba(0, 0, 0, 0.773546918767507) 84%);
}

.projectImageP {
    position: absolute;
    color: #ffffff;
    text-align: left;
    background-color: #000000;
    font-size: 16px;
}

.projectImageCard {
    overflow-y: auto;
    cursor: pointer;
    position: relative;
}

.buttonMenu {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: auto;
}

.projectImageCard * {
    overflow-y: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}

.projectImageCard img {
    position: relative;
    max-width: 100%;
    vertical-align: top;
    object-fit: cover;
    object-position: top;
}

.projectImageCard figcaption {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    display: inline-flex !important;
    flex-direction: column;
    justify-content: end;
}

.projectImageCard figcaption:before,
.projectImageCard figcaption:after {
    width: 2px;
    height: 0;
}

.projectImageCard figcaption:before {
    right: 0;
    top: 0;
}

.projectImageCard figcaption:after {
    left: 0;
    bottom: 0;
}

.projectImageCard h3,
.projectImageCard p {
    line-height: 1.5em;
}

.projectImageCard h3 {
    margin: 0 0 5px;
    font-weight: 700;
    text-transform: uppercase;
}

.projectImageCard p {
    font-size: 0.8em;
    font-weight: 500;
    margin: 0 0 15px;
}

.projectImageCard a {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.projectImageCard:before,
.projectImageCard:after,
.projectImageCard figcaption:before,
.projectImageCard figcaption:after {
    position: absolute;
    content: '';
    background: linear-gradient(0.25turn, $main-color, $main-color-dark);
    z-index: 1;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    opacity: 0.8;
}

.projectImageCard:before,
.projectImageCard:after {
    height: 2px;
    width: 0%;
}

.projectImageCard:before {
    top: 0;
    left: 0;
}

.projectImageCard:after {
    bottom: 0;
    right: 0;
}

.projectVideoContainer:last-of-type {
    margin-bottom: 35px;
}

.projectVideoContainer {
    max-width: 1280px;
    max-height: 720px;
    cursor: pointer;
    margin-top: 35px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: $main-color !important;
    border: 0px;
}

.menuScroll {
    z-index: 2;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
}

.settings {
    margin-top: auto;
}

.App button:hover,
.App button.active {
    color: $main-color;
}

.App button {
    font-family: 'Antonio', 'sans-serif';
    font-size: 17px;
    margin: 0rem 0.25rem;
    border: 0px;
    background-color: transparent;
    display: inline-block;
    padding: 0.5rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.projectImageCard.active figcaption,
.projectImageCard:hover figcaption,
.projectImageCard.hover figcaption {
    opacity: 1;
}

.projectImageCard.active figcaption:after,
.projectImageCard.active figcaption:before,
.projectImageCard:hover figcaption:before,
.projectImageCard.hover figcaption:before,
.projectImageCard:hover figcaption:after,
.projectImageCard.hover figcaption:after {
    height: 100%;
}

.projectImageCard.active:after,
.projectImageCard.active:before,
.projectImageCard:hover:before,
.projectImageCard.hover:before,
.projectImageCard:hover:after,
.projectImageCard.hover:after {
    width: 100%;
}

.projectImageCard.active figcaption:after,
.projectImageCard.active figcaption:before,
.projectImageCard.active:after,
.projectImageCard.active:before,
.projectImageCard:hover:before,
.projectImageCard.hover:before,
.projectImageCard:hover:after,
.projectImageCard.hover:after,
.projectImageCard:hover figcaption:before,
.projectImageCard.hover figcaption:before,
.projectImageCard:hover figcaption:after,
.projectImageCard.hover figcaption:after {
    opacity: 0.5;
}

.iconsHoverNav:hover {
    transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    cursor: pointer;
}

.iconsHoverNav {
    transition: transform 0.3s ease;
}

.iconsHover:hover {
    transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    cursor: pointer;
    color: $main-color;
}

.iconsHover {
    transition: transform 0.3s ease;
}

.videoFooter div:first-of-type {
    text-align: start;
}

.videoFooter>div:last-of-type {
    text-align: end;
}

.setAddress {
    cursor: pointer;
}

.flex-center {
    text-align: center;
}

@media (max-width: 767px) {
    .flex-center {
        text-align: end;
    }

    .videoFooter div:first-of-type {
        margin-bottom: 0.5rem;
    }

    .videoFooter>div:last-child {
        text-align: center !important;
    }

    .sliding-div {
        padding: 20px 20px 95px 20px !important;
    }
}

.opacity-text {
    color: rgba(255 255 255 / 0.5);
}

.borderBlock {
    border: 1px solid rgba(255 255 255 /0.2);
}

.borderBottom {
    border-bottom: 1px solid rgba(255 255 255 /0.2);
}

.borderRight {
    border-right: 1px solid rgba(255 255 255 /0.2);
}

.borderLeft {
    border-left: 1px solid rgba(255 255 255 /0.2);
}

.borderTop {
    border-top: 1px solid rgba(255 255 255 /0.2);
}

.borderFooterImg {
    height: 50px;
    margin: 0rem 1rem 0rem 1rem;
}

.sliding-div .borderBlock {
    border-radius: 5px;
    align-items: center;
}

.sliding-div p {
    margin: 0px;
    font-size: 12px;
}

.vertical-bar {
    height: 100%;
    width: 5px;
    background-color: rgba(255 255 255 /0.2);
}

.sliding-div {
    z-index: 1;
    display: flex;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
    background-color: $main-color;
    padding: 20px 20px 55px 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: transform 0.5s ease;
    transform: translateY(100%);
}

.technologies .container-sm {
    max-width: 950px;
}

.technologies,
.projects,
.about {
    z-index: -3;
    position: relative;
}

.about .container-sm {
    max-width: 950px;
}

.about .clients .col {
    text-align: center;
}

.about .clients img {
    border: none;
    outline: none;
}

.aboutFirstText {
    max-width: 1000px;
    margin: auto;
}

@media (max-width:776px) {
    .aboutMiddleCard div {
        border-bottom: 2px solid rgb(75, 167, 0);
        border-right: 0px !important;
        padding: 2rem !important;
        margin-bottom: 0rem;
    }

    .aboutMiddleCard div:last-of-type {
        border: 0px !important;
        border-right: 0px !important;
        margin-bottom: 0px !important;
    }
}

.aboutMiddleCard {
    margin: 0rem;
    border-radius: 10px;
    color: #fff;
    padding: 3rem 1rem 3rem 1rem;
}

.aboutMiddleCard div h3 {
    margin-bottom: 0rem;
}

.aboutMiddleCard div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutMiddleCard div:first-of-type {
    border-right: 2px solid $main-color;
}

.aboutMiddleCard div:last-of-type {
    border-left: 2px solid $main-color;
}

.aboutMiddleCard img {
    padding: 1rem;
    width: 100%;
}

.visible {
    transform: translateY(0);
    /* Slide the div back into view */
}

.section_our_solution .row {
    align-items: center;
}

.our_solution_category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.our_solution_category .solution_cards_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.solution_cards_box .solution_card {
    display: flex;
    flex-direction: column;
    flex: 0 50%;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
        0 5px 15px 0 rgba(37, 44, 97, 0.15);
    border-radius: 15px;
    padding: 15px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 200px;
    transition: 0.7s;
}

.solution_cards_box .solution_card:hover {
    background: $main-color;
    color: #fff;
    z-index: 9;
}

.solution_cards_box .solution_card:hover::before {
    background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card:hover .solu_description svg {
    color: $main-color;
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
    color: #fff !important;
}

.solution_cards_box .solution_card:before {
    content: "";
    position: absolute;
    background: $main-color;
    width: 170px;
    height: 400px;
    z-index: -1;
    transform: rotate(42deg);
    right: -56px;
    top: -23px;
    border-radius: 35px;
    opacity: 0.25;
}

.solution_cards_box .solution_card:hover .solu_description button {
    background: #fff !important;
    color: $main-color;
}

.solution_card .solu_title h3 {
    color: #000;
    font-size: 1.3rem;
    margin-bottom: 13px;
}

.solution_card .solu_description p {
    color: #000;
    font-size: 15px;
    margin-bottom: 15px;
}

.solution_card .solu_description button {
    border: 0;
    border-radius: 15px;
    background: linear-gradient(0.25turn, $main-color, $main-color-dark);
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 16px;
}

.our_solution_content h1 {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-size: 2.5rem;
}

.our_solution_content p {}

.hover_color_bubble {
    position: absolute;
    background: rgb(54 81 207 / 15%);
    width: 100rem;
    height: 100rem;
    left: 0;
    right: 0;
    z-index: -1;
    top: 16rem;
    border-radius: 50%;
    transform: rotate(-36deg);
    left: -18rem;
    transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
    top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
    width: 40px;
    height: 50px;
    object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
    .sol_card_top_3 {
        position: relative;
        top: 0;
    }

    .our_solution_category {
        width: 100%;
        margin: 0 auto;
    }

    .our_solution_category .solution_cards_box {
        flex: auto;
    }
}

@media only screen and (min-width: 768px) {
    .our_solution_category .solution_cards_box {
        flex: 1;
    }
}

@media only screen and (min-width: 1024px) {
    .sol_card_top_3 {
        position: relative;
        top: -3rem;
    }

    .our_solution_category {
        width: 50%;
        margin: 0 auto;
    }
}


.relatedProjectsSwipe {
    width: 100%;
    height: 200px;
}

.relatedProjectsSwipe .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.relatedProjectsSwipe .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.relatedProjectsSwipe .swiper-slide {
    width: 20%;
}

.relatedProjectsSwipe .swiper-slide:nth-child(2n) {
    width: 20%;
}

.relatedProjectsSwipe .swiper-slide:nth-child(3n) {
    width: 20%;
}

.module {
    background-color: #000;

    >h2 {
        padding: 1rem;
        margin: 1rem 0 0.5rem 0;
    }

    >p {
        padding: 0 1rem;
    }
}

.stripe-3 {
    margin: 1.5rem 0rem 1rem 0rem;
    height: 175px;
    background-position: top;
    background: linear-gradient(45deg,
            $main-color-opacity,
            $main-color-opacity 10px,
            $main-color-dark-opacity 10px,
            $main-color-dark-opacity 20px),
        url('../images/v996-008.jpg');
}

.module {
    position: relative;
}

.module::before,
.module::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    /* Height of the gradient merging effect, adjust as needed */
    background: linear-gradient(to bottom, #000, transparent);
    /* Adjust gradient colors as needed */
}


.module::before {
    top: 0;
}

.module::after {
    bottom: 0;
    transform: scaleY(-1);
    /* Flip the gradient to create a bottom merging effect */
}

.swiper-pagination-bullet-active {
    background-color: $main-color;
}

.floatContainer {
    text-align: right;
    bottom: 20px;
    right: 20px;
    position: fixed;
}

.floatButton {
    cursor: pointer;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
}

.floatButtonClose {
    text-align: end;
    cursor: pointer;
    text-align: right;
    color: #FFF;
}

.my-floatButton {
    margin-top: 14px;
}