.text-hover {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover:after,
.text-hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover:after,
.text-hover:before {
    background-color: #fff
}

.text-hover:focus,
.text-hover:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover:focus:after,
.text-hover:focus:before,
.text-hover:hover:after,
.text-hover:hover:before {
    background-color: #fff
}

.text-hover-halfline-bottom-goes-left,
.text-hover:focus:after,
.text-hover:focus:before,
.text-hover:hover:after,
.text-hover:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-goes-left {
    display: inline;
    padding: 10px;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-bottom-goes-left:after,
.text-hover-halfline-bottom-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-goes-left:after {
    background-color: #fff
}

.text-hover-halfline-bottom-goes-left:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-goes-left:focus,
.text-hover-halfline-bottom-goes-left:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-bottom-goes-left:focus:after,
.text-hover-halfline-bottom-goes-left:hover:after {
    background-color: #fff
}

.text-hover-halfline-bottom-goes-left:focus:before,
.text-hover-halfline-bottom-goes-left:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-goes-left:focus:after,
.text-hover-halfline-bottom-goes-left:focus:before,
.text-hover-halfline-bottom-goes-left:hover:after,
.text-hover-halfline-bottom-goes-left:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-hover-halfline-bottom-goes-left:focus:after,
.text-hover-halfline-bottom-goes-left:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-bottom-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 50%;
    height: 3px
}

.text-hover-halfline-bottom-goes-left:focus:before,
.text-hover-halfline-bottom-goes-left:hover:before {
    outline: 0;
    transition-property: right, background-color;
    right: 50%
}

.text-hover-halfline-bottom-goes-left:after {
    right: 0;
    left: 0;
    bottom: 0
}

.text-hover-halfline-bottom-goes-left:before {
    z-index: 1;
    right: 0;
    bottom: 0
}

.text-hover-halfline-bottom-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-bottom-goes-right:after,
.text-hover-halfline-bottom-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-goes-right:after {
    background-color: #fff
}

.text-hover-halfline-bottom-goes-right:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-goes-right:focus,
.text-hover-halfline-bottom-goes-right:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-bottom-goes-right:focus:after,
.text-hover-halfline-bottom-goes-right:hover:after {
    background-color: #fff
}

.text-hover-halfline-bottom-goes-right:focus:before,
.text-hover-halfline-bottom-goes-right:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-goes-right:focus:after,
.text-hover-halfline-bottom-goes-right:focus:before,
.text-hover-halfline-bottom-goes-right:hover:after,
.text-hover-halfline-bottom-goes-right:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-hover-halfline-bottom-goes-right:focus:after,
.text-hover-halfline-bottom-goes-right:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-bottom-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 50%;
    height: 3px
}

.text-hover-halfline-bottom-goes-right:focus:before,
.text-hover-halfline-bottom-goes-right:hover:before {
    outline: 0;
    transition-property: left, background-color;
    left: 50%
}

.text-hover-halfline-bottom-goes-right:after {
    left: 0;
    bottom: 0
}

.text-hover-halfline-bottom-goes-right:before {
    z-index: 1;
    left: 0;
    bottom: 0
}

.text-hover-halfline-top-goes-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-top-goes-left:after,
.text-hover-halfline-top-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-goes-left:after {
    background-color: #fff
}

.text-hover-halfline-top-goes-left:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-goes-left:focus,
.text-hover-halfline-top-goes-left:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-top-goes-left:focus:after,
.text-hover-halfline-top-goes-left:hover:after {
    background-color: #fff
}

.text-hover-halfline-top-goes-left:focus:before,
.text-hover-halfline-top-goes-left:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-goes-left:focus:after,
.text-hover-halfline-top-goes-left:focus:before,
.text-hover-halfline-top-goes-left:hover:after,
.text-hover-halfline-top-goes-left:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-hover-halfline-top-goes-left:focus:after,
.text-hover-halfline-top-goes-left:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-top-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 50%;
    height: 3px
}

.text-hover-halfline-top-goes-left:focus:before,
.text-hover-halfline-top-goes-left:hover:before {
    outline: 0;
    transition-property: right, background-color;
    right: 50%
}

.text-hover-halfline-top-goes-left:after {
    right: 0;
    left: 0;
    top: 0
}

.text-hover-halfline-top-goes-left:before {
    z-index: 1;
    right: 0;
    top: 0
}

.text-hover-halfline-top-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-top-goes-right:after,
.text-hover-halfline-top-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-goes-right:after {
    background-color: #fff
}

.text-hover-halfline-top-goes-right:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-goes-right:focus,
.text-hover-halfline-top-goes-right:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-top-goes-right:focus:after,
.text-hover-halfline-top-goes-right:hover:after {
    background-color: #fff
}

.text-hover-halfline-top-goes-right:focus:before,
.text-hover-halfline-top-goes-right:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-goes-right:focus:after,
.text-hover-halfline-top-goes-right:focus:before,
.text-hover-halfline-top-goes-right:hover:after,
.text-hover-halfline-top-goes-right:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-hover-halfline-top-goes-right:focus:after,
.text-hover-halfline-top-goes-right:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-top-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 50%;
    height: 3px
}

.text-hover-halfline-top-goes-right:focus:before,
.text-hover-halfline-top-goes-right:hover:before {
    outline: 0;
    transition-property: left, background-color;
    left: 50%
}

.text-hover-halfline-top-goes-right:after {
    left: 0;
    top: 0
}

.text-hover-halfline-top-goes-right:before {
    z-index: 1;
    left: 0;
    top: 0
}

.text-hover-halfline-bottom-left-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-bottom-left-goes-up:after,
.text-hover-halfline-bottom-left-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-left-goes-up:after {
    background-color: #fff
}

.text-hover-halfline-bottom-left-goes-up:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-left-goes-up:focus,
.text-hover-halfline-bottom-left-goes-up:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-bottom-left-goes-up:focus:after,
.text-hover-halfline-bottom-left-goes-up:hover:after {
    background-color: #fff
}

.text-hover-halfline-bottom-left-goes-up:focus:before,
.text-hover-halfline-bottom-left-goes-up:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-left-goes-up:focus:after,
.text-hover-halfline-bottom-left-goes-up:focus:before,
.text-hover-halfline-bottom-left-goes-up:hover:after,
.text-hover-halfline-bottom-left-goes-up:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-left-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-hover-halfline-bottom-left-goes-up:focus:after,
.text-hover-halfline-bottom-left-goes-up:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-bottom-left-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-hover-halfline-bottom-left-goes-up:before,
.text-hover-halfline-bottom-left-goes-up:focus:before,
.text-hover-halfline-bottom-left-goes-up:hover:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-hover-halfline-bottom-left-goes-up:focus:before,
.text-hover-halfline-bottom-left-goes-up:hover:before {
    outline: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.text-hover-halfline-bottom-left-goes-up:after {
    left: 0;
    bottom: 0
}

.text-hover-halfline-bottom-left-goes-up:before {
    z-index: 1;
    left: 0;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-hover-halfline-bottom-right-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-bottom-right-goes-up:after,
.text-hover-halfline-bottom-right-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-right-goes-up:after {
    background-color: #fff
}

.text-hover-halfline-bottom-right-goes-up:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-right-goes-up:focus,
.text-hover-halfline-bottom-right-goes-up:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-bottom-right-goes-up:focus:after,
.text-hover-halfline-bottom-right-goes-up:hover:after {
    background-color: #fff
}

.text-hover-halfline-bottom-right-goes-up:focus:before,
.text-hover-halfline-bottom-right-goes-up:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-bottom-right-goes-up:focus:after,
.text-hover-halfline-bottom-right-goes-up:focus:before,
.text-hover-halfline-bottom-right-goes-up:hover:after,
.text-hover-halfline-bottom-right-goes-up:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-bottom-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-hover-halfline-bottom-right-goes-up:focus:after,
.text-hover-halfline-bottom-right-goes-up:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-bottom-right-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-hover-halfline-bottom-right-goes-up:before,
.text-hover-halfline-bottom-right-goes-up:focus:before,
.text-hover-halfline-bottom-right-goes-up:hover:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-hover-halfline-bottom-right-goes-up:focus:before,
.text-hover-halfline-bottom-right-goes-up:hover:before {
    outline: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.text-hover-halfline-bottom-right-goes-up:after {
    right: 0;
    bottom: 0
}

.text-hover-halfline-bottom-right-goes-up:before {
    z-index: 1;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-hover-halfline-top-left-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-top-left-goes-down:after,
.text-hover-halfline-top-left-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-left-goes-down:after {
    background-color: #fff
}

.text-hover-halfline-top-left-goes-down:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-left-goes-down:focus,
.text-hover-halfline-top-left-goes-down:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-top-left-goes-down:focus:after,
.text-hover-halfline-top-left-goes-down:hover:after {
    background-color: #fff
}

.text-hover-halfline-top-left-goes-down:focus:before,
.text-hover-halfline-top-left-goes-down:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-left-goes-down:focus:after,
.text-hover-halfline-top-left-goes-down:focus:before,
.text-hover-halfline-top-left-goes-down:hover:after,
.text-hover-halfline-top-left-goes-down:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-left-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-hover-halfline-top-left-goes-down:focus:after,
.text-hover-halfline-top-left-goes-down:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-top-left-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-hover-halfline-top-left-goes-down:before,
.text-hover-halfline-top-left-goes-down:focus:before,
.text-hover-halfline-top-left-goes-down:hover:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-hover-halfline-top-left-goes-down:focus:before,
.text-hover-halfline-top-left-goes-down:hover:before {
    outline: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.text-hover-halfline-top-left-goes-down:after {
    left: 0;
    top: 0
}

.text-hover-halfline-top-left-goes-down:before {
    z-index: 1;
    left: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-hover-halfline-top-right-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-halfline-top-right-goes-down:after,
.text-hover-halfline-top-right-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-right-goes-down:after {
    background-color: #fff
}

.text-hover-halfline-top-right-goes-down:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-right-goes-down:focus,
.text-hover-halfline-top-right-goes-down:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-halfline-top-right-goes-down:focus:after,
.text-hover-halfline-top-right-goes-down:hover:after {
    background-color: #fff
}

.text-hover-halfline-top-right-goes-down:focus:before,
.text-hover-halfline-top-right-goes-down:hover:before {
    background-color: #4dabc8
}

.text-hover-halfline-top-right-goes-down:focus:after,
.text-hover-halfline-top-right-goes-down:focus:before,
.text-hover-halfline-top-right-goes-down:hover:after,
.text-hover-halfline-top-right-goes-down:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-halfline-top-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-hover-halfline-top-right-goes-down:focus:after,
.text-hover-halfline-top-right-goes-down:hover:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-hover-halfline-top-right-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-hover-halfline-top-right-goes-down:before,
.text-hover-halfline-top-right-goes-down:focus:before,
.text-hover-halfline-top-right-goes-down:hover:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-hover-halfline-top-right-goes-down:focus:before,
.text-hover-halfline-top-right-goes-down:hover:before {
    outline: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.text-hover-halfline-top-right-goes-down:after {
    right: 0;
    top: 0
}

.text-hover-halfline-top-right-goes-down:before {
    z-index: 1;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-hover-cc-left-bottom:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-cc-left-bottom:focus:after,
.text-hover-cc-left-bottom:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-cc-left-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-cc-left-bottom:focus:before,
.text-hover-cc-left-bottom:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-cc-left-bottom:before {
    top: 0;
    left: 0
}

.text-hover-cc-left-bottom:after {
    bottom: 0;
    right: 0
}

.text-hover-cc-left-top:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-cc-left-top:focus:after,
.text-hover-cc-left-top:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-cc-left-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-cc-left-top:focus:before,
.text-hover-cc-left-top:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-cc-left-top:before {
    bottom: 0;
    left: 0
}

.text-hover-cc-left-top:after {
    top: 0;
    right: 0
}

.text-hover-cc-right-bottom:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-cc-right-bottom:focus:after,
.text-hover-cc-right-bottom:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-cc-right-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-cc-right-bottom:focus:before,
.text-hover-cc-right-bottom:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-cc-right-bottom:before {
    top: 0;
    right: 0
}

.text-hover-cc-right-bottom:after {
    bottom: 0;
    left: 0
}

.text-hover-cc-right-top:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-cc-right-top:focus:after,
.text-hover-cc-right-top:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-cc-right-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-cc-right-top:focus:before,
.text-hover-cc-right-top:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-cc-right-top:before {
    bottom: 0;
    right: 0
}

.text-hover-cc-right-top:after {
    top: 0;
    left: 0
}

.text-hover-ce-bottom-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-ce-bottom-left:focus:after,
.text-hover-ce-bottom-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-ce-bottom-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-ce-bottom-left:focus:before,
.text-hover-ce-bottom-left:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-ce-bottom-left:after,
.text-hover-ce-bottom-left:before {
    bottom: 0;
    left: 0
}

.text-hover-ce-bottom-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-ce-bottom-right:focus:after,
.text-hover-ce-bottom-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-ce-bottom-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-ce-bottom-right:focus:before,
.text-hover-ce-bottom-right:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-ce-bottom-right:after,
.text-hover-ce-bottom-right:before {
    bottom: 0;
    right: 0
}

.text-hover-ce-top-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-ce-top-left:focus:after,
.text-hover-ce-top-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-ce-top-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-ce-top-left:focus:before,
.text-hover-ce-top-left:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-ce-top-left:after,
.text-hover-ce-top-left:before {
    top: 0;
    left: 0
}

.text-hover-ce-top-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-ce-top-right:focus:after,
.text-hover-ce-top-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-ce-top-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-ce-top-right:focus:before,
.text-hover-ce-top-right:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-ce-top-right:after,
.text-hover-ce-top-right:before {
    top: 0;
    right: 0
}

.text-hover-both-go-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-both-go-down:focus:after,
.text-hover-both-go-down:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-both-go-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-both-go-down:focus:before,
.text-hover-both-go-down:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-both-go-down:after {
    top: 0;
    left: 0
}

.text-hover-both-go-down:before {
    top: 0;
    right: 0
}

.text-hover-both-go-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-go-left:focus:after,
.text-hover-both-go-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-go-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-go-left:focus:before,
.text-hover-both-go-left:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-go-left:after {
    top: 0;
    right: 0
}

.text-hover-both-go-left:before {
    bottom: 0;
    right: 0
}

.text-hover-both-go-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-go-right:focus:after,
.text-hover-both-go-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-go-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-go-right:focus:before,
.text-hover-both-go-right:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-go-right:after {
    top: 0;
    left: 0
}

.text-hover-both-go-right:before {
    bottom: 0;
    left: 0
}

.text-hover-both-go-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-both-go-up:focus:after,
.text-hover-both-go-up:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-both-go-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-both-go-up:focus:before,
.text-hover-both-go-up:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-both-go-up:after {
    bottom: 0;
    left: 0
}

.text-hover-both-go-up:before {
    bottom: 0;
    right: 0
}

.text-hover-both-opening-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-opening-horizontal:focus:after,
.text-hover-both-opening-horizontal:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-opening-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-both-opening-horizontal:focus:before,
.text-hover-both-opening-horizontal:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-both-opening-horizontal:after {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-hover-both-opening-horizontal:before {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-hover-overline-goes-left-underline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-left-underline-goes-right:focus:after,
.text-hover-overline-goes-left-underline-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-left-underline-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-left-underline-goes-right:focus:before,
.text-hover-overline-goes-left-underline-goes-right:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-left-underline-goes-right:after {
    top: 0;
    right: 0
}

.text-hover-overline-goes-left-underline-goes-right:before {
    bottom: 0;
    left: 0
}

.text-hover-overline-goes-right-underline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-right-underline-goes-left:focus:after,
.text-hover-overline-goes-right-underline-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-right-underline-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-right-underline-goes-left:focus:before,
.text-hover-overline-goes-right-underline-goes-left:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-right-underline-goes-left:after {
    top: 0;
    left: 0
}

.text-hover-overline-goes-right-underline-goes-left:before {
    bottom: 0;
    right: 0
}

.text-hover-left-goes-up-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-up-right-goes-down:focus:after,
.text-hover-left-goes-up-right-goes-down:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-up-right-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-up-right-goes-down:focus:before,
.text-hover-left-goes-up-right-goes-down:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-up-right-goes-down:after {
    bottom: 0;
    left: 0
}

.text-hover-left-goes-up-right-goes-down:before {
    top: 0;
    right: 0
}

.text-hover-left-goes-down-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-down-right-goes-up:focus:after,
.text-hover-left-goes-down-right-goes-up:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-down-right-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-down-right-goes-up:focus:before,
.text-hover-left-goes-down-right-goes-up:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-down-right-goes-up:after {
    top: 0;
    left: 0
}

.text-hover-left-goes-down-right-goes-up:before {
    bottom: 0;
    right: 0
}

.text-hover-du-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-goes-left:focus:after,
.text-hover-du-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-goes-left:focus:before,
.text-hover-du-goes-left:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-goes-left:after {
    right: 0;
    bottom: 0
}

.text-hover-du-goes-left:before {
    right: 0;
    bottom: 10px
}

.text-hover-du-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-goes-right:focus:after,
.text-hover-du-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-goes-right:focus:before,
.text-hover-du-goes-right:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-goes-right:after {
    left: 0;
    bottom: 0
}

.text-hover-du-goes-right:before {
    left: 0;
    bottom: 10px
}

.text-hover-du-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-opening:focus:after,
.text-hover-du-opening:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-opening:focus:before,
.text-hover-du-opening:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-opening:after {
    right: 0;
    bottom: 0;
    margin: 0 auto;
    left: 0
}

.text-hover-du-opening:before {
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    left: 0
}

.text-hover-du-reversed-lr:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-reversed-lr:focus:after,
.text-hover-du-reversed-lr:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-reversed-lr:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-reversed-lr:focus:before,
.text-hover-du-reversed-lr:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-reversed-lr:after {
    left: 0;
    bottom: 0
}

.text-hover-du-reversed-lr:before {
    right: 0;
    bottom: 10px
}

.text-hover-du-reversed-rl:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-reversed-rl:focus:after,
.text-hover-du-reversed-rl:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-reversed-rl:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-du-reversed-rl:focus:before,
.text-hover-du-reversed-rl:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-du-reversed-rl:after {
    right: 0;
    bottom: 0
}

.text-hover-du-reversed-rl:before {
    left: 0;
    bottom: 10px
}

.text-hover-dl-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-goes-left:focus:after,
.text-hover-dl-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-goes-left:focus:before,
.text-hover-dl-goes-left:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-goes-left:after {
    right: 0;
    top: 45%;
    z-index: 9
}

.text-hover-dl-goes-left:before {
    right: 0;
    bottom: 45%;
    z-index: 9;
    top: 54%
}

.text-hover-dl-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-goes-right:focus:after,
.text-hover-dl-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-goes-right:focus:before,
.text-hover-dl-goes-right:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-goes-right:after {
    left: 0;
    top: 44%;
    z-index: 9
}

.text-hover-dl-goes-right:before {
    left: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-hover-dl-reversed-lr:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-reversed-lr:focus:after,
.text-hover-dl-reversed-lr:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-reversed-lr:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-reversed-lr:focus:before,
.text-hover-dl-reversed-lr:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-reversed-lr:after {
    right: 0;
    top: 44%;
    z-index: 9
}

.text-hover-dl-reversed-lr:before {
    left: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-hover-dl-reversed-rl:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-reversed-rl:focus:after,
.text-hover-dl-reversed-rl:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-reversed-rl:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-reversed-rl:focus:before,
.text-hover-dl-reversed-rl:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-reversed-rl:after {
    left: 0;
    top: 44%;
    z-index: 9
}

.text-hover-dl-reversed-rl:before {
    right: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-hover-dl-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-opening:focus:after,
.text-hover-dl-opening:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-dl-opening:focus:before,
.text-hover-dl-opening:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-dl-opening:after {
    left: 0;
    top: 54%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-hover-dl-opening:before {
    left: 0;
    top: 44%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-hover-overline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-left:focus:after,
.text-hover-overline-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-left:after {
    right: 0;
    top: 0
}

.text-hover-overline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-goes-right:focus:after,
.text-hover-overline-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-goes-right:after {
    left: 0;
    top: 0
}

.text-hover-overline-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-closing:focus:after,
.text-hover-overline-closing:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-overline-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-closing:focus:before,
.text-hover-overline-closing:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-overline-closing:after {
    top: 0;
    left: 0
}

.text-hover-overline-closing:before {
    top: 0;
    right: 0
}

.text-hover-overline-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-opening:focus:after,
.text-hover-overline-opening:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-overline-opening:focus:before,
.text-hover-overline-opening:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-overline-opening:after,
.text-hover-overline-opening:before {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-hover-underline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-goes-left:focus:after,
.text-hover-underline-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-underline-goes-left:after {
    right: 0;
    bottom: 0
}

.text-hover-underline-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-closing:focus:after,
.text-hover-underline-closing:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-underline-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-closing:focus:before,
.text-hover-underline-closing:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-underline-closing:after {
    bottom: 0;
    left: 0
}

.text-hover-underline-closing:before {
    bottom: 0;
    right: 0
}

.text-hover-underline-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-opening:focus:after,
.text-hover-underline-opening:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-underline-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-opening:focus:before,
.text-hover-underline-opening:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-underline-opening:after,
.text-hover-underline-opening:before {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-hover-underline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-goes-right:focus:after,
.text-hover-underline-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-underline-goes-right:after {
    left: 0;
    bottom: 0
}

.text-hover-left-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-down:focus:after,
.text-hover-left-goes-down:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-down:after {
    top: 0;
    left: 0
}

.text-hover-left-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-left-goes-up:focus:after,
.text-hover-left-goes-up:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-left-goes-up:after {
    bottom: 0;
    left: 0
}

.text-hover-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-right-goes-up:focus:after,
.text-hover-right-goes-up:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-right-goes-up:after {
    bottom: 0;
    right: 0
}

.text-hover-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-right-goes-down:focus:after,
.text-hover-right-goes-down:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-right-goes-down:after {
    top: 0;
    right: 0
}

.text-hover-linethrough-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-linethrough-opening:focus:after,
.text-hover-linethrough-opening:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-linethrough-opening:after {
    left: 0;
    top: 50%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-hover-linethrough-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-linethrough-closing:focus:after,
.text-hover-linethrough-closing:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-linethrough-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-linethrough-closing:focus:before,
.text-hover-linethrough-closing:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-linethrough-closing:after {
    left: 0;
    top: 50%;
    z-index: 9
}

.text-hover-linethrough-closing:before {
    top: 50%;
    right: 0;
    z-index: 9
}

.text-hover-linethrough-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-linethrough-goes-left:focus:after,
.text-hover-linethrough-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-linethrough-goes-left:after {
    right: 0;
    top: 50%;
    z-index: 9
}

.text-hover-linethrough-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-linethrough-goes-right:focus:after,
.text-hover-linethrough-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-linethrough-goes-right:after {
    left: 0;
    top: 50%;
    z-index: 9
}

.text-hover-move-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: top, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-down:focus:after,
.text-hover-move-down:hover:after {
    outline: 0;
    transition-property: top, background-color;
    top: 100%
}

.text-hover-move-down:after {
    background-color: initial;
    top: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-hover-move-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: bottom, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-up:focus:after,
.text-hover-move-up:hover:after {
    outline: 0;
    transition-property: bottom, background-color;
    bottom: 100%
}

.text-hover-move-up:after {
    background-color: initial;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-hover-move-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-right:focus:after,
.text-hover-move-right:hover:after {
    outline: 0;
    transition-property: right, background-color;
    right: 0
}

.text-hover-move-right:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto
}

.text-hover-move-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-left:focus:after,
.text-hover-move-left:hover:after {
    outline: 0;
    transition-property: left, background-color;
    left: 0
}

.text-hover-move-left:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto
}

.text-hover-move-vertical:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: top, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-vertical:focus:after,
.text-hover-move-vertical:hover:after {
    outline: 0;
    transition-property: top, background-color;
    top: 0
}

.text-hover-move-vertical:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: bottom, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-vertical:focus:before,
.text-hover-move-vertical:hover:before {
    outline: 0;
    transition-property: bottom, background-color;
    bottom: 0
}

.text-hover-move-vertical:after {
    background-color: initial;
    top: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-hover-move-vertical:before {
    background-color: initial;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-hover-move-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-horizontal:focus:after,
.text-hover-move-horizontal:hover:after {
    outline: 0;
    transition-property: right, background-color;
    right: 0
}

.text-hover-move-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 3px;
    height: 3px
}

.text-hover-move-horizontal:focus:before,
.text-hover-move-horizontal:hover:before {
    outline: 0;
    transition-property: left, background-color;
    left: 0
}

.text-hover-move-horizontal:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto
}

.text-hover-move-horizontal:before {
    background-color: initial;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto
}

.text-hover-fill-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-goes-up:after,
.text-hover-fill-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-up:after,
.text-hover-fill-goes-up:before {
    background-color: #fff
}

.text-hover-fill-goes-up:focus,
.text-hover-fill-goes-up:hover {
    outline: 0;
    cursor: pointer;
    color: #4ba700;
    transition: color .7s ease-in-out
}

.text-hover-fill-goes-up:focus:after,
.text-hover-fill-goes-up:focus:before,
.text-hover-fill-goes-up:hover:after,
.text-hover-fill-goes-up:hover:before {
    background-color: #fff
}

.text-hover-fill-goes-up:focus:after,
.text-hover-fill-goes-up:focus:before,
.text-hover-fill-goes-up:hover:after,
.text-hover-fill-goes-up:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-fill-goes-up:focus:after,
.text-hover-fill-goes-up:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-fill-goes-up:after {
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%
}

.text-hover-fill-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-goes-down:after,
.text-hover-fill-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-down:after,
.text-hover-fill-goes-down:before {
    background-color: #fff
}

.text-hover-fill-goes-down:focus,
.text-hover-fill-goes-down:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-goes-down:focus:after,
.text-hover-fill-goes-down:focus:before,
.text-hover-fill-goes-down:hover:after,
.text-hover-fill-goes-down:hover:before {
    background-color: #fff
}

.text-hover-fill-goes-down:focus:after,
.text-hover-fill-goes-down:focus:before,
.text-hover-fill-goes-down:hover:after,
.text-hover-fill-goes-down:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-fill-goes-down:focus:after,
.text-hover-fill-goes-down:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-hover-fill-goes-down:after {
    top: 0;
    right: 0;
    left: 0;
    width: 100%
}

.text-hover-fill-goes-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-goes-left:after,
.text-hover-fill-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-left:after,
.text-hover-fill-goes-left:before {
    background-color: #fff
}

.text-hover-fill-goes-left:focus,
.text-hover-fill-goes-left:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-goes-left:focus:after,
.text-hover-fill-goes-left:focus:before,
.text-hover-fill-goes-left:hover:after,
.text-hover-fill-goes-left:hover:before {
    background-color: #fff
}

.text-hover-fill-goes-left:focus:after,
.text-hover-fill-goes-left:focus:before,
.text-hover-fill-goes-left:hover:after,
.text-hover-fill-goes-left:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-fill-goes-left:focus:after,
.text-hover-fill-goes-left:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-fill-goes-left:after {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%
}

.text-hover-fill-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-goes-right:after,
.text-hover-fill-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-right:after,
.text-hover-fill-goes-right:before {
    background-color: #fff
}

.text-hover-fill-goes-right:focus,
.text-hover-fill-goes-right:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-goes-right:focus:after,
.text-hover-fill-goes-right:focus:before,
.text-hover-fill-goes-right:hover:after,
.text-hover-fill-goes-right:hover:before {
    background-color: #fff
}

.text-hover-fill-goes-right:focus:after,
.text-hover-fill-goes-right:focus:before,
.text-hover-fill-goes-right:hover:after,
.text-hover-fill-goes-right:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-fill-goes-right:focus:after,
.text-hover-fill-goes-right:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-fill-goes-right:after {
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%
}

.text-hover-fill-opening-horizontal {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-opening-horizontal:after,
.text-hover-fill-opening-horizontal:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-opening-horizontal:after,
.text-hover-fill-opening-horizontal:before {
    background-color: #fff
}

.text-hover-fill-opening-horizontal:focus,
.text-hover-fill-opening-horizontal:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-opening-horizontal:focus:after,
.text-hover-fill-opening-horizontal:focus:before,
.text-hover-fill-opening-horizontal:hover:after,
.text-hover-fill-opening-horizontal:hover:before {
    background-color: #fff
}

.text-hover-fill-opening-horizontal:focus:after,
.text-hover-fill-opening-horizontal:focus:before,
.text-hover-fill-opening-horizontal:hover:after,
.text-hover-fill-opening-horizontal:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-opening-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-fill-opening-horizontal:focus:after,
.text-hover-fill-opening-horizontal:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-fill-opening-horizontal:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto
}

.text-hover-fill-closing-horizontal {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-closing-horizontal:after,
.text-hover-fill-closing-horizontal:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-closing-horizontal:after,
.text-hover-fill-closing-horizontal:before {
    background-color: #fff
}

.text-hover-fill-closing-horizontal:focus,
.text-hover-fill-closing-horizontal:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-closing-horizontal:focus:after,
.text-hover-fill-closing-horizontal:focus:before,
.text-hover-fill-closing-horizontal:hover:after,
.text-hover-fill-closing-horizontal:hover:before {
    background-color: #fff
}

.text-hover-fill-closing-horizontal:focus:after,
.text-hover-fill-closing-horizontal:focus:before,
.text-hover-fill-closing-horizontal:hover:after,
.text-hover-fill-closing-horizontal:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-closing-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-fill-closing-horizontal:focus:after,
.text-hover-fill-closing-horizontal:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-fill-closing-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-fill-closing-horizontal:focus:before,
.text-hover-fill-closing-horizontal:hover:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-hover-fill-closing-horizontal:before {
    top: 0;
    bottom: 0;
    left: 0;
    height: auto
}

.text-hover-fill-closing-horizontal:after {
    top: 0;
    bottom: 0;
    right: 0;
    height: auto
}

.text-hover-fill-closing-vertical {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fill-closing-vertical:after,
.text-hover-fill-closing-vertical:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-closing-vertical:after,
.text-hover-fill-closing-vertical:before {
    background-color: #fff
}

.text-hover-fill-closing-vertical:focus,
.text-hover-fill-closing-vertical:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fill-closing-vertical:focus:after,
.text-hover-fill-closing-vertical:focus:before,
.text-hover-fill-closing-vertical:hover:after,
.text-hover-fill-closing-vertical:hover:before {
    background-color: #fff
}

.text-hover-fill-closing-vertical:focus:after,
.text-hover-fill-closing-vertical:focus:before,
.text-hover-fill-closing-vertical:hover:after,
.text-hover-fill-closing-vertical:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fill-closing-vertical:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-fill-closing-vertical:focus:after,
.text-hover-fill-closing-vertical:hover:after {
    outline: 0;
    transition-property: height, background-color;
    height: 50%
}

.text-hover-fill-closing-vertical:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-hover-fill-closing-vertical:focus:before,
.text-hover-fill-closing-vertical:hover:before {
    outline: 0;
    transition-property: height, background-color;
    height: 50%
}

.text-hover-fill-closing-vertical:before {
    top: 0;
    right: 0;
    left: 0;
    width: auto
}

.text-hover-fill-closing-vertical:after {
    bottom: 0;
    left: 0;
    right: 0;
    width: auto
}

.text-hover-fc-top-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fc-top-left:after,
.text-hover-fc-top-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-top-left:after,
.text-hover-fc-top-left:before {
    background-color: #fff
}

.text-hover-fc-top-left:focus,
.text-hover-fc-top-left:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fc-top-left:focus:after,
.text-hover-fc-top-left:focus:before,
.text-hover-fc-top-left:hover:after,
.text-hover-fc-top-left:hover:before {
    background-color: #fff
}

.text-hover-fc-top-left:focus:after,
.text-hover-fc-top-left:focus:before,
.text-hover-fc-top-left:hover:after,
.text-hover-fc-top-left:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-top-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-hover-fc-top-left:focus:after,
.text-hover-fc-top-left:hover:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-hover-fc-top-left:after {
    top: 0;
    left: 0
}

.text-hover-fc-top-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fc-top-right:after,
.text-hover-fc-top-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-top-right:after,
.text-hover-fc-top-right:before {
    background-color: #fff
}

.text-hover-fc-top-right:focus,
.text-hover-fc-top-right:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fc-top-right:focus:after,
.text-hover-fc-top-right:focus:before,
.text-hover-fc-top-right:hover:after,
.text-hover-fc-top-right:hover:before {
    background-color: #fff
}

.text-hover-fc-top-right:focus:after,
.text-hover-fc-top-right:focus:before,
.text-hover-fc-top-right:hover:after,
.text-hover-fc-top-right:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-top-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-hover-fc-top-right:focus:after,
.text-hover-fc-top-right:hover:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-hover-fc-top-right:after {
    top: 0;
    right: 0
}

.text-hover-fc-bottom-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fc-bottom-left:after,
.text-hover-fc-bottom-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-bottom-left:after,
.text-hover-fc-bottom-left:before {
    background-color: #fff
}

.text-hover-fc-bottom-left:focus,
.text-hover-fc-bottom-left:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fc-bottom-left:focus:after,
.text-hover-fc-bottom-left:focus:before,
.text-hover-fc-bottom-left:hover:after,
.text-hover-fc-bottom-left:hover:before {
    background-color: #fff
}

.text-hover-fc-bottom-left:focus:after,
.text-hover-fc-bottom-left:focus:before,
.text-hover-fc-bottom-left:hover:after,
.text-hover-fc-bottom-left:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-bottom-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-hover-fc-bottom-left:focus:after,
.text-hover-fc-bottom-left:hover:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-hover-fc-bottom-left:after {
    bottom: 0;
    right: 0
}

.text-hover-fc-bottom-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-fc-bottom-right:after,
.text-hover-fc-bottom-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-bottom-right:after,
.text-hover-fc-bottom-right:before {
    background-color: #fff
}

.text-hover-fc-bottom-right:focus,
.text-hover-fc-bottom-right:hover {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-hover-fc-bottom-right:focus:after,
.text-hover-fc-bottom-right:focus:before,
.text-hover-fc-bottom-right:hover:after,
.text-hover-fc-bottom-right:hover:before {
    background-color: #fff
}

.text-hover-fc-bottom-right:focus:after,
.text-hover-fc-bottom-right:focus:before,
.text-hover-fc-bottom-right:hover:after,
.text-hover-fc-bottom-right:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-hover-fc-bottom-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-hover-fc-bottom-right:focus:after,
.text-hover-fc-bottom-right:hover:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-hover-fc-bottom-right:after {
    bottom: 0;
    left: 0
}

.text-hover-underline-left-delay {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 1s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-hover-underline-left-delay:after,
.text-hover-underline-left-delay:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 1s
}

.text-hover-underline-left-delay:after,
.text-hover-underline-left-delay:before {
    background-color: #fff
}

.text-hover-underline-left-delay:focus,
.text-hover-underline-left-delay:hover {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-hover-underline-left-delay:focus:after,
.text-hover-underline-left-delay:focus:before,
.text-hover-underline-left-delay:hover:after,
.text-hover-underline-left-delay:hover:before {
    background-color: #fff
}

.text-hover-underline-left-delay:focus:after,
.text-hover-underline-left-delay:focus:before,
.text-hover-underline-left-delay:hover:after,
.text-hover-underline-left-delay:hover:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 1s
}

.text-hover-underline-left-delay:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-hover-underline-left-delay:focus:after,
.text-hover-underline-left-delay:hover:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-hover-underline-left-delay:after {
    right: 0;
    bottom: 0
}

.text-click {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click:after,
.text-click:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click:after,
.text-click:before {
    background-color: #fff
}

.text-click.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click.active:after,
.text-click.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-goes-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-bottom-goes-left:after,
.text-click-halfline-bottom-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-goes-left:after {
    background-color: #fff
}

.text-click-halfline-bottom-goes-left:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-goes-left.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-bottom-goes-left.active:after {
    background-color: #fff
}

.text-click-halfline-bottom-goes-left.active:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-goes-left.active:after,
.text-click-halfline-bottom-goes-left.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-click-halfline-bottom-goes-left.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-bottom-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 50%;
    height: 3px
}

.text-click-halfline-bottom-goes-left.active:before {
    outline: 0;
    transition-property: right, background-color;
    right: 50%
}

.text-click-halfline-bottom-goes-left:after {
    right: 0;
    left: 0;
    bottom: 0
}

.text-click-halfline-bottom-goes-left:before {
    z-index: 1;
    right: 0;
    bottom: 0
}

.text-click-halfline-bottom-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-bottom-goes-right:after,
.text-click-halfline-bottom-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-goes-right:after {
    background-color: #fff
}

.text-click-halfline-bottom-goes-right:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-goes-right.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-bottom-goes-right.active:after {
    background-color: #fff
}

.text-click-halfline-bottom-goes-right.active:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-goes-right.active:after,
.text-click-halfline-bottom-goes-right.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-click-halfline-bottom-goes-right.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-bottom-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 50%;
    height: 3px
}

.text-click-halfline-bottom-goes-right.active:before {
    outline: 0;
    transition-property: left, background-color;
    left: 50%
}

.text-click-halfline-bottom-goes-right:after {
    left: 0;
    bottom: 0
}

.text-click-halfline-bottom-goes-right:before {
    z-index: 1;
    left: 0;
    bottom: 0
}

.text-click-halfline-top-goes-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-top-goes-left:after,
.text-click-halfline-top-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-goes-left:after {
    background-color: #fff
}

.text-click-halfline-top-goes-left:before {
    background-color: #4dabc8
}

.text-click-halfline-top-goes-left.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-top-goes-left.active:after {
    background-color: #fff
}

.text-click-halfline-top-goes-left.active:before {
    background-color: #4dabc8
}

.text-click-halfline-top-goes-left.active:after,
.text-click-halfline-top-goes-left.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-click-halfline-top-goes-left.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-top-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 50%;
    height: 3px
}

.text-click-halfline-top-goes-left.active:before {
    outline: 0;
    transition-property: right, background-color;
    right: 50%
}

.text-click-halfline-top-goes-left:after {
    right: 0;
    left: 0;
    top: 0
}

.text-click-halfline-top-goes-left:before {
    z-index: 1;
    right: 0;
    top: 0
}

.text-click-halfline-top-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-top-goes-right:after,
.text-click-halfline-top-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-goes-right:after {
    background-color: #fff
}

.text-click-halfline-top-goes-right:before {
    background-color: #4dabc8
}

.text-click-halfline-top-goes-right.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-top-goes-right.active:after {
    background-color: #fff
}

.text-click-halfline-top-goes-right.active:before {
    background-color: #4dabc8
}

.text-click-halfline-top-goes-right.active:after,
.text-click-halfline-top-goes-right.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 100%;
    height: 3px
}

.text-click-halfline-top-goes-right.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-top-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 50%;
    height: 3px
}

.text-click-halfline-top-goes-right.active:before {
    outline: 0;
    transition-property: left, background-color;
    left: 50%
}

.text-click-halfline-top-goes-right:after {
    left: 0;
    top: 0
}

.text-click-halfline-top-goes-right:before {
    z-index: 1;
    left: 0;
    top: 0
}

.text-click-halfline-bottom-left-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-bottom-left-goes-up:after,
.text-click-halfline-bottom-left-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-left-goes-up:after {
    background-color: #fff
}

.text-click-halfline-bottom-left-goes-up:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-left-goes-up.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-bottom-left-goes-up.active:after {
    background-color: #fff
}

.text-click-halfline-bottom-left-goes-up.active:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-left-goes-up.active:after,
.text-click-halfline-bottom-left-goes-up.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-left-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-click-halfline-bottom-left-goes-up.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-bottom-left-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-click-halfline-bottom-left-goes-up.active:before,
.text-click-halfline-bottom-left-goes-up:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-click-halfline-bottom-left-goes-up.active:before {
    outline: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.text-click-halfline-bottom-left-goes-up:after {
    left: 0;
    bottom: 0
}

.text-click-halfline-bottom-left-goes-up:before {
    z-index: 1;
    left: 0;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-click-halfline-bottom-right-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-bottom-right-goes-up:after,
.text-click-halfline-bottom-right-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-right-goes-up:after {
    background-color: #fff
}

.text-click-halfline-bottom-right-goes-up:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-right-goes-up.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-bottom-right-goes-up.active:after {
    background-color: #fff
}

.text-click-halfline-bottom-right-goes-up.active:before {
    background-color: #4dabc8
}

.text-click-halfline-bottom-right-goes-up.active:after,
.text-click-halfline-bottom-right-goes-up.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-bottom-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-click-halfline-bottom-right-goes-up.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-bottom-right-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-click-halfline-bottom-right-goes-up.active:before,
.text-click-halfline-bottom-right-goes-up:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-click-halfline-bottom-right-goes-up.active:before {
    outline: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.text-click-halfline-bottom-right-goes-up:after {
    right: 0;
    bottom: 0
}

.text-click-halfline-bottom-right-goes-up:before {
    z-index: 1;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-click-halfline-top-left-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-top-left-goes-down:after,
.text-click-halfline-top-left-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-left-goes-down:after {
    background-color: #fff
}

.text-click-halfline-top-left-goes-down:before {
    background-color: #4dabc8
}

.text-click-halfline-top-left-goes-down.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-top-left-goes-down.active:after {
    background-color: #fff
}

.text-click-halfline-top-left-goes-down.active:before {
    background-color: #4dabc8
}

.text-click-halfline-top-left-goes-down.active:after,
.text-click-halfline-top-left-goes-down.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-left-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-click-halfline-top-left-goes-down.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-top-left-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-click-halfline-top-left-goes-down.active:before,
.text-click-halfline-top-left-goes-down:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-click-halfline-top-left-goes-down.active:before {
    outline: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.text-click-halfline-top-left-goes-down:after {
    left: 0;
    top: 0
}

.text-click-halfline-top-left-goes-down:before {
    z-index: 1;
    left: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-click-halfline-top-right-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-halfline-top-right-goes-down:after,
.text-click-halfline-top-right-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-right-goes-down:after {
    background-color: #fff
}

.text-click-halfline-top-right-goes-down:before {
    background-color: #4dabc8
}

.text-click-halfline-top-right-goes-down.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-halfline-top-right-goes-down.active:after {
    background-color: #fff
}

.text-click-halfline-top-right-goes-down.active:before {
    background-color: #4dabc8
}

.text-click-halfline-top-right-goes-down.active:after,
.text-click-halfline-top-right-goes-down.active:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-halfline-top-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: none;
    width: 3px;
    height: 100%
}

.text-click-halfline-top-right-goes-down.active:after {
    outline: 0;
    transition-property: none;
    none: 100%
}

.text-click-halfline-top-right-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 3px;
    height: 50%
}

.text-click-halfline-top-right-goes-down.active:before,
.text-click-halfline-top-right-goes-down:before {
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform
}

.text-click-halfline-top-right-goes-down.active:before {
    outline: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.text-click-halfline-top-right-goes-down:after {
    right: 0;
    top: 0
}

.text-click-halfline-top-right-goes-down:before {
    z-index: 1;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.text-click-cc-left-bottom:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-cc-left-bottom.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-cc-left-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-cc-left-bottom.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-cc-left-bottom:before {
    top: 0;
    left: 0
}

.text-click-cc-left-bottom:after {
    bottom: 0;
    right: 0
}

.text-click-cc-left-top:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-cc-left-top.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-cc-left-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-cc-left-top.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-cc-left-top:before {
    bottom: 0;
    left: 0
}

.text-click-cc-left-top:after {
    top: 0;
    right: 0
}

.text-click-cc-right-bottom:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-cc-right-bottom.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-cc-right-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-cc-right-bottom.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-cc-right-bottom:before {
    top: 0;
    right: 0
}

.text-click-cc-right-bottom:after {
    bottom: 0;
    left: 0
}

.text-click-cc-right-top:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-cc-right-top.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-cc-right-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-cc-right-top.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-cc-right-top:before {
    bottom: 0;
    right: 0
}

.text-click-cc-right-top:after {
    top: 0;
    left: 0
}

.text-click-ce-bottom-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-ce-bottom-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-ce-bottom-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-ce-bottom-left.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-ce-bottom-left:after,
.text-click-ce-bottom-left:before {
    bottom: 0;
    left: 0
}

.text-click-ce-bottom-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-ce-bottom-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-ce-bottom-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-ce-bottom-right.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-ce-bottom-right:after,
.text-click-ce-bottom-right:before {
    bottom: 0;
    right: 0
}

.text-click-ce-top-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-ce-top-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-ce-top-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-ce-top-left.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-ce-top-left:after,
.text-click-ce-top-left:before {
    top: 0;
    left: 0
}

.text-click-ce-top-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-ce-top-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-ce-top-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-ce-top-right.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-ce-top-right:after,
.text-click-ce-top-right:before {
    top: 0;
    right: 0
}

.text-click-both-go-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-both-go-down.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-both-go-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-both-go-down.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-both-go-down:after {
    top: 0;
    left: 0
}

.text-click-both-go-down:before {
    top: 0;
    right: 0
}

.text-click-both-go-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-go-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-go-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-go-left.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-go-left:after {
    top: 0;
    right: 0
}

.text-click-both-go-left:before {
    bottom: 0;
    right: 0
}

.text-click-both-go-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-go-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-go-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-go-right.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-go-right:after {
    top: 0;
    left: 0
}

.text-click-both-go-right:before {
    bottom: 0;
    left: 0
}

.text-click-both-go-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-both-go-up.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-both-go-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-both-go-up.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-both-go-up:after {
    bottom: 0;
    left: 0
}

.text-click-both-go-up:before {
    bottom: 0;
    right: 0
}

.text-click-both-opening-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-opening-horizontal.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-opening-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-both-opening-horizontal.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-both-opening-horizontal:after {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-click-both-opening-horizontal:before {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-click-overline-goes-left-underline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-left-underline-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-left-underline-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-left-underline-goes-right.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-left-underline-goes-right:after {
    top: 0;
    right: 0
}

.text-click-overline-goes-left-underline-goes-right:before {
    bottom: 0;
    left: 0
}

.text-click-overline-goes-right-underline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-right-underline-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-right-underline-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-right-underline-goes-left.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-right-underline-goes-left:after {
    top: 0;
    left: 0
}

.text-click-overline-goes-right-underline-goes-left:before {
    bottom: 0;
    right: 0
}

.text-click-left-goes-up-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-up-right-goes-down.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-up-right-goes-down:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-up-right-goes-down.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-up-right-goes-down:after {
    bottom: 0;
    left: 0
}

.text-click-left-goes-up-right-goes-down:before {
    top: 0;
    right: 0
}

.text-click-left-goes-down-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-down-right-goes-up.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-down-right-goes-up:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-down-right-goes-up.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-down-right-goes-up:after {
    top: 0;
    left: 0
}

.text-click-left-goes-down-right-goes-up:before {
    bottom: 0;
    right: 0
}

.text-click-du-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-goes-left.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-goes-left:after {
    right: 0;
    bottom: 0
}

.text-click-du-goes-left:before {
    right: 0;
    bottom: 10px
}

.text-click-du-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-goes-right.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-goes-right:after {
    left: 0;
    bottom: 0
}

.text-click-du-goes-right:before {
    left: 0;
    bottom: 10px
}

.text-click-du-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-opening.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-opening.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-opening:after {
    right: 0;
    bottom: 0;
    margin: 0 auto;
    left: 0
}

.text-click-du-opening:before {
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    left: 0
}

.text-click-du-reversed-lr:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-reversed-lr.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-reversed-lr:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-reversed-lr.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-reversed-lr:after {
    left: 0;
    bottom: 0
}

.text-click-du-reversed-lr:before {
    right: 0;
    bottom: 10px
}

.text-click-du-reversed-rl:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-reversed-rl.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-reversed-rl:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-du-reversed-rl.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-du-reversed-rl:after {
    right: 0;
    bottom: 0
}

.text-click-du-reversed-rl:before {
    left: 0;
    bottom: 10px
}

.text-click-dl-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-goes-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-goes-left.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-goes-left:after {
    right: 0;
    top: 45%;
    z-index: 9
}

.text-click-dl-goes-left:before {
    right: 0;
    bottom: 45%;
    z-index: 9;
    top: 54%
}

.text-click-dl-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-goes-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-goes-right.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-goes-right:after {
    left: 0;
    top: 44%;
    z-index: 9
}

.text-click-dl-goes-right:before {
    left: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-click-dl-reversed-lr:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-reversed-lr.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-reversed-lr:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-reversed-lr.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-reversed-lr:after {
    right: 0;
    top: 44%;
    z-index: 9
}

.text-click-dl-reversed-lr:before {
    left: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-click-dl-reversed-rl:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-reversed-rl.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-reversed-rl:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-reversed-rl.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-reversed-rl:after {
    left: 0;
    top: 44%;
    z-index: 9
}

.text-click-dl-reversed-rl:before {
    right: 0;
    bottom: 10px;
    z-index: 9;
    top: 54%
}

.text-click-dl-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-opening.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-dl-opening.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-dl-opening:after {
    left: 0;
    top: 54%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-click-dl-opening:before {
    left: 0;
    top: 44%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-click-overline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-left:after {
    right: 0;
    top: 0
}

.text-click-overline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-goes-right:after {
    left: 0;
    top: 0
}

.text-click-overline-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-closing.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-overline-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-closing.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-overline-closing:after {
    top: 0;
    left: 0
}

.text-click-overline-closing:before {
    top: 0;
    right: 0
}

.text-click-overline-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-opening.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-overline-opening.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-overline-opening:after,
.text-click-overline-opening:before {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-click-underline-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-underline-goes-left:after {
    right: 0;
    bottom: 0
}

.text-click-underline-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-closing.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-underline-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-closing.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-underline-closing:after {
    bottom: 0;
    left: 0
}

.text-click-underline-closing:before {
    bottom: 0;
    right: 0
}

.text-click-underline-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-opening.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 75%
}

.text-click-underline-opening:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-opening.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 75%
}

.text-click-underline-opening:after,
.text-click-underline-opening:before {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.text-click-underline-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-underline-goes-right:after {
    left: 0;
    bottom: 0
}

.text-click-left-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-down.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-down:after {
    top: 0;
    left: 0
}

.text-click-left-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-left-goes-up.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-left-goes-up:after {
    bottom: 0;
    left: 0
}

.text-click-right-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-right-goes-up.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-right-goes-up:after {
    bottom: 0;
    right: 0
}

.text-click-right-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-right-goes-down.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-right-goes-down:after {
    top: 0;
    right: 0
}

.text-click-linethrough-opening:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-linethrough-opening.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-linethrough-opening:after {
    left: 0;
    top: 50%;
    z-index: 9;
    right: 0;
    margin: 0 auto
}

.text-click-linethrough-closing:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-linethrough-closing.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-linethrough-closing:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-linethrough-closing.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-linethrough-closing:after {
    left: 0;
    top: 50%;
    z-index: 9
}

.text-click-linethrough-closing:before {
    top: 50%;
    right: 0;
    z-index: 9
}

.text-click-linethrough-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-linethrough-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-linethrough-goes-left:after {
    right: 0;
    top: 50%;
    z-index: 9
}

.text-click-linethrough-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-linethrough-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-linethrough-goes-right:after {
    left: 0;
    top: 50%;
    z-index: 9
}

.text-click-move-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: top, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-down.active:after {
    outline: 0;
    transition-property: top, background-color;
    top: 100%
}

.text-click-move-down:after {
    background-color: initial;
    top: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-click-move-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: bottom, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-up.active:after {
    outline: 0;
    transition-property: bottom, background-color;
    bottom: 100%
}

.text-click-move-up:after {
    background-color: initial;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-click-move-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-right.active:after {
    outline: 0;
    transition-property: right, background-color;
    right: 0
}

.text-click-move-right:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto
}

.text-click-move-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-left.active:after {
    outline: 0;
    transition-property: left, background-color;
    left: 0
}

.text-click-move-left:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto
}

.text-click-move-vertical:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: top, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-vertical.active:after {
    outline: 0;
    transition-property: top, background-color;
    top: 0
}

.text-click-move-vertical:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: bottom, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-vertical.active:before {
    outline: 0;
    transition-property: bottom, background-color;
    bottom: 0
}

.text-click-move-vertical:after {
    background-color: initial;
    top: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-click-move-vertical:before {
    background-color: initial;
    bottom: 50%;
    left: 0;
    right: 0;
    width: auto
}

.text-click-move-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: right, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-horizontal.active:after {
    outline: 0;
    transition-property: right, background-color;
    right: 0
}

.text-click-move-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: left, background-color;
    width: 3px;
    height: 3px
}

.text-click-move-horizontal.active:before {
    outline: 0;
    transition-property: left, background-color;
    left: 0
}

.text-click-move-horizontal:after {
    background-color: initial;
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto
}

.text-click-move-horizontal:before {
    background-color: initial;
    top: 0;
    bottom: 0;
    left: 50%;
    height: auto
}

.text-click-fill-goes-up {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-goes-up:after,
.text-click-fill-goes-up:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-up:after,
.text-click-fill-goes-up:before {
    background-color: #fff
}

.text-click-fill-goes-up.active {
    outline: 0;
    cursor: pointer;
    color: #4ba700;
    transition: color .7s ease-in-out
}

.text-click-fill-goes-up.active:after,
.text-click-fill-goes-up.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-up:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-fill-goes-up.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-fill-goes-up:after {
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%
}

.text-click-fill-goes-down {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-goes-down:after,
.text-click-fill-goes-down:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-down:after,
.text-click-fill-goes-down:before {
    background-color: #fff
}

.text-click-fill-goes-down.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-goes-down.active:after,
.text-click-fill-goes-down.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-down:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-fill-goes-down.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 100%
}

.text-click-fill-goes-down:after {
    top: 0;
    right: 0;
    left: 0;
    width: 100%
}

.text-click-fill-goes-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-goes-left:after,
.text-click-fill-goes-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-left:after,
.text-click-fill-goes-left:before {
    background-color: #fff
}

.text-click-fill-goes-left.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-goes-left.active:after,
.text-click-fill-goes-left.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-fill-goes-left.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-fill-goes-left:after {
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%
}

.text-click-fill-goes-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-goes-right:after,
.text-click-fill-goes-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-right:after,
.text-click-fill-goes-right:before {
    background-color: #fff
}

.text-click-fill-goes-right.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-goes-right.active:after,
.text-click-fill-goes-right.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-goes-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-fill-goes-right.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-fill-goes-right:after {
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%
}

.text-click-fill-opening-horizontal {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-opening-horizontal:after,
.text-click-fill-opening-horizontal:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-opening-horizontal:after,
.text-click-fill-opening-horizontal:before {
    background-color: #fff
}

.text-click-fill-opening-horizontal.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-opening-horizontal.active:after,
.text-click-fill-opening-horizontal.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-opening-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-fill-opening-horizontal.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-fill-opening-horizontal:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto
}

.text-click-fill-closing-horizontal {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-closing-horizontal:after,
.text-click-fill-closing-horizontal:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-closing-horizontal:after,
.text-click-fill-closing-horizontal:before {
    background-color: #fff
}

.text-click-fill-closing-horizontal.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-closing-horizontal.active:after,
.text-click-fill-closing-horizontal.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-closing-horizontal:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-fill-closing-horizontal.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-fill-closing-horizontal:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-fill-closing-horizontal.active:before {
    outline: 0;
    transition-property: width, background-color;
    width: 50%
}

.text-click-fill-closing-horizontal:before {
    top: 0;
    bottom: 0;
    left: 0;
    height: auto
}

.text-click-fill-closing-horizontal:after {
    top: 0;
    bottom: 0;
    right: 0;
    height: auto
}

.text-click-fill-closing-vertical {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fill-closing-vertical:after,
.text-click-fill-closing-vertical:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-closing-vertical:after,
.text-click-fill-closing-vertical:before {
    background-color: #fff
}

.text-click-fill-closing-vertical.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fill-closing-vertical.active:after,
.text-click-fill-closing-vertical.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fill-closing-vertical:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-fill-closing-vertical.active:after {
    outline: 0;
    transition-property: height, background-color;
    height: 50%
}

.text-click-fill-closing-vertical:before {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: height, background-color;
    height: 0;
    width: 3px
}

.text-click-fill-closing-vertical.active:before {
    outline: 0;
    transition-property: height, background-color;
    height: 50%
}

.text-click-fill-closing-vertical:before {
    top: 0;
    right: 0;
    left: 0;
    width: auto
}

.text-click-fill-closing-vertical:after {
    bottom: 0;
    left: 0;
    right: 0;
    width: auto
}

.text-click-fc-top-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fc-top-left:after,
.text-click-fc-top-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-top-left:after,
.text-click-fc-top-left:before {
    background-color: #fff
}

.text-click-fc-top-left.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fc-top-left.active:after,
.text-click-fc-top-left.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-top-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-click-fc-top-left.active:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-click-fc-top-left:after {
    top: 0;
    left: 0
}

.text-click-fc-top-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fc-top-right:after,
.text-click-fc-top-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-top-right:after,
.text-click-fc-top-right:before {
    background-color: #fff
}

.text-click-fc-top-right.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fc-top-right.active:after,
.text-click-fc-top-right.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-top-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-click-fc-top-right.active:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-click-fc-top-right:after {
    top: 0;
    right: 0
}

.text-click-fc-bottom-left {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fc-bottom-left:after,
.text-click-fc-bottom-left:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-bottom-left:after,
.text-click-fc-bottom-left:before {
    background-color: #fff
}

.text-click-fc-bottom-left.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fc-bottom-left.active:after,
.text-click-fc-bottom-left.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-bottom-left:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-click-fc-bottom-left.active:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-click-fc-bottom-left:after {
    bottom: 0;
    right: 0
}

.text-click-fc-bottom-right {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 0s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-fc-bottom-right:after,
.text-click-fc-bottom-right:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-bottom-right:after,
.text-click-fc-bottom-right:before {
    background-color: #fff
}

.text-click-fc-bottom-right.active {
    outline: 0;
    cursor: pointer;
    color: #fcfafa;
    transition: color .7s ease-in-out
}

.text-click-fc-bottom-right.active:after,
.text-click-fc-bottom-right.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s
}

.text-click-fc-bottom-right:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, height, background-color;
    width: 0;
    height: 0
}

.text-click-fc-bottom-right.active:after {
    outline: 0;
    transition-property: width, height, background-color;
    width: 100%;
    height: 100%
}

.text-click-fc-bottom-right:after {
    bottom: 0;
    left: 0
}

.text-click-underline-left-delay {
    display: inline;
    padding: 10px;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-delay: 1s;
    transition-property: color;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 2;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.text-click-underline-left-delay:after,
.text-click-underline-left-delay:before {
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 1s
}

.text-click-underline-left-delay:after,
.text-click-underline-left-delay:before {
    background-color: #fff
}

.text-click-underline-left-delay.active {
    outline: 0;
    cursor: pointer;
    color: #fff;
    transition: color .7s ease-in-out
}

.text-click-underline-left-delay.active:after,
.text-click-underline-left-delay.active:before {
    background-color: #fff;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
    transition-delay: 1s
}

.text-click-underline-left-delay:after {
    content: "";
    position: absolute;
    z-index: -1;
    transition-property: width, background-color;
    width: 0;
    height: 3px
}

.text-click-underline-left-delay.active:after {
    outline: 0;
    transition-property: width, background-color;
    width: 100%
}

.text-click-underline-left-delay:after {
    right: 0;
    bottom: 0
}